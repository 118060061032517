import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import '../home.modules.css';
import KeyboardHint from "./KeyboardHint";
import OneRightArrow from "../Icons/onerightright.png"
import OneLeftArrow from "../Icons/arrow-left.png"
import TwoRightArrows from "../Icons/twoarrowsright.png"
import TwoLeftArrows from "../Icons/twoarrowsleft.png"

interface Passage {
    reference: string;
    text: string;
  }

function PracticeText(props: any) {

  let selectedPassage = localStorage.getItem("passage")
  let selectedTranslation = localStorage.getItem('translation')

  const [count, setCount] = useState(1);
  const [passage, setPassage] = useState<Passage>();

  const fetchPassage = async () => {
    const response = await fetch (`https://bible-api.com/${selectedPassage}?translation=${selectedTranslation}`);
    const data = await response.json(); 
    setPassage(data)
  };

  useEffect(() => {
    fetchPassage()
    setCount(1)
  }, [localStorage.getItem('passage')]);

  useEffect(() => {
    fetchPassage()
  }, [localStorage.getItem('translation')]);

  let text = passage?.text
  let fullText = ""
  if (text != null) {
    fullText = text.trim().replace(/(\r\n|\n|\r)/gm, " "); 
  }
  
  let fullTextArray: string[] = fullText.split(/[' '\n]+/)
  for(let i = 0; i < fullTextArray.length; i++) {
    fullTextArray[i] = fullTextArray[i] + " "
  }
  
  const currentText = () => {
    let currentVerseText: string[] = fullTextArray.slice(0, count)
    return currentVerseText
  }

  const displayFullVerse = () => {
    setCount(fullTextArray.length)
  }

  const startOver = () => {
    setCount(1)
  }

  const addOneWord = () => {
    if (count < fullTextArray.length) {
      setCount(count + 1)
    }
  }

  const subOneWord = () => {
    if (count > 1) {
      setCount(count - 1)
    }
  }

  const keyDownEvent = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.code === "ArrowRight") {
      document.getElementById('addBtn')!.click();
    }
    if (event.code === "ArrowLeft") {
      document.getElementById('subBtn')!.click();
    }
    if (event.code === "ArrowDown") {
      document.getElementById('startOverBtn')!.click();
    }
    if (event.code === "ArrowUp") {
      document.getElementById('allBtn')!.click();
    }
  }

  function OperationsButton(image: string, onClickElement: any, id: string) {
    return(
      <motion.button 
        onClick = {onClickElement} 
        className = "Home-operationsButtonStyle" 
        id = {id}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        > 
        <img className="Home-operations-icons" src={image} alt="image icon" />
      </motion.button>
    )
  }

  return (
      <>
        <button
        className = "Home-textField"
        onKeyDown = {keyDownEvent}
        id = "Home-textField"
        >
        {KeyboardHint()}
        {currentText()}
        </button>   
        <div className = "Home-operationButtonsRow">
          {OperationsButton(TwoLeftArrows, startOver, "startOverBtn")}
          {OperationsButton(OneLeftArrow, subOneWord, "subBtn")}
          {OperationsButton(OneRightArrow, addOneWord, "addBtn")}
          {OperationsButton(TwoRightArrows, displayFullVerse, "allBtn")}
        </div>
      </>
  )
}
export default PracticeText