import React, { useEffect, useState } from "react";
import "./Selectors.modules.css"

interface Verses {
    data: Array<verseArray>;
}

interface verseArray {
    id: string;
    chapterId: string;
}

export default function Verse(props: any) {
    let book = props.book
    let chapter = props.chapter
    const [verses, setVerses] = useState<Verses>();
    const fetchVerse = async () => {
        const response = await fetch (
        `https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-02/chapters/${book}.${chapter}/verses`, {
            headers: {
                "api-key": "4763a1cc274c12d0850e11218008dd79"
            }
        }
    );
    const data = await response.json(); 
    setVerses(data)
    };

    useEffect(() => {
        fetchVerse()
    }, []);

    const [selectedFirstVerse, setSelectedFirstVerse] = useState("");
    const [selectedSecondVerse, setSelectedSecondVerse] = useState("");
    const [mouseOverSecondVerse, setMouseOverSecondVerse] = useState<number>(parseInt(selectedFirstVerse))
    const verseRange = `${selectedFirstVerse}-${selectedSecondVerse}`
    const [clickEvent, setClickEvent] = useState(1)
    const [firstVerseIndex, setFirstVerseIndex] = useState(-1);
    const [secondVerseIndex, setSecondVerseIndex] = useState(-1);

    useEffect(() => {
        if (selectedFirstVerse !== "" && selectedSecondVerse !== "" && selectedFirstVerse !== selectedSecondVerse) {
            props.verses(verseRange)
        }
    
        if (selectedFirstVerse !== "" && selectedFirstVerse === selectedSecondVerse) {
            props.verses(selectedFirstVerse)
        }
    }, [selectedFirstVerse, selectedSecondVerse, props, verseRange])


    return(
        <div className="Verse-button-row"> 
            {verses?.data.map((verseArray, index) => {
                let verse = verseArray.id.split(".")[2]
                let verseIndex = index;
                if (firstVerseIndex === index) {
                    return( 
                        <button 
                            key={index} 
                            className="Verse-buttons-selected" 
                            onMouseOver={() => {if (clickEvent % 2 === 0) setMouseOverSecondVerse(index)}}
                            onClick={() => {
                                if (clickEvent % 2 !== 0) {
                                    setFirstVerseIndex(verseIndex); 
                                    setSecondVerseIndex(verseIndex);
                                    setClickEvent(clickEvent + 1);
                                    setSelectedFirstVerse(verse);
                                    setSelectedSecondVerse("");
                                } else { 
                                    if (verseIndex > firstVerseIndex) 
                                        setSecondVerseIndex(verseIndex); 
                                        setClickEvent(clickEvent + 1);
                                        setSelectedSecondVerse(verse);
                                    }
                                }
                            }> 
                                <h2 className="Verse-button-text-selected" key={verseArray.id}> {verse} </h2>
                        </button>
                    )
                } else if (secondVerseIndex === index) {
                    return(
                        <button 
                            key={index} 
                            className="Verse-buttons-selected" 
                            onMouseOver={() => {if (clickEvent % 2 === 0) setMouseOverSecondVerse(index)}}
                            onClick={() => {
                                if (clickEvent % 2 !== 0) {
                                    setFirstVerseIndex(verseIndex); 
                                    setSecondVerseIndex(verseIndex);
                                    setClickEvent(clickEvent + 1);
                                    setSelectedFirstVerse(verse);
                                } else { 
                                    if (verseIndex > firstVerseIndex) 
                                    setSecondVerseIndex(verseIndex); 
                                    setClickEvent(clickEvent + 1);
                                    setSelectedSecondVerse(verse);
                                    }
                                }
                            }> 
                            <h2 className="Verse-button-text-selected" key={verseArray.id}> {verse} </h2>
                        </button>
                    )
                } else if (index < mouseOverSecondVerse && index > firstVerseIndex) {
                    return(
                        <button 
                            key={index} 
                            className="Verse-buttons-selected" 
                            onMouseOver={() => {if (clickEvent % 2 === 0) setMouseOverSecondVerse(index)}}
                            onClick={() => { 
                                if (clickEvent % 2 !== 0) {
                                    setFirstVerseIndex(verseIndex); 
                                    setSecondVerseIndex(verseIndex);
                                    setClickEvent(clickEvent + 1);
                                    setSelectedFirstVerse(verse);
                                } else { 
                                    if (verseIndex > firstVerseIndex) 
                                    setSecondVerseIndex(verseIndex); 
                                    setClickEvent(clickEvent + 1);
                                    setSelectedSecondVerse(verse);
                                    }
                                }
                            }> 
                            <h2 className="Verse-button-text-range" key={verseArray.id}> {verse} </h2>
                        </button>
                    )
                }
                else {
                    return( 
                        <button 
                            key={index} 
                            className="Verse-buttons" 
                            onMouseOver={() => {if (clickEvent % 2 === 0) setMouseOverSecondVerse(index)}}
                            onClick={() => { 
                                if (clickEvent % 2 !== 0) {
                                    setFirstVerseIndex(verseIndex);
                                    setSecondVerseIndex(verseIndex);
                                    setClickEvent(clickEvent + 1);
                                    setSelectedFirstVerse(verse);
                                } else { 
                                    if (verseIndex > firstVerseIndex) 
                                    setSecondVerseIndex(verseIndex); 
                                    setClickEvent(clickEvent + 1);
                                    setSelectedSecondVerse(verse);
                                    }
                                }
                            }> 
                            <h2 className="Verse-button-text" key={verseArray.id}> {verse} </h2>
                        </button>
                    )
                }
            })}
        </div>
    )
}