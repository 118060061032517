import { motion } from "framer-motion";
import GreenToggleCircle from "./NewVersefromHome/Icons/SelectedGreenIcon.png"
import './home.modules.css';
import { useEffect, useState } from "react";
import styled from 'styled-components'

const ToggleBackground = styled(motion.div)`
    width: 15vw;
    min-width: fit-content;
    height: 5vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
`;

const ToggleRectangle = styled(motion.div)`
    width: 50%;
    min-width: fit-content;
    height: 100%;
    position: absolute;
    background-color: #e9e0d1;
    border-radius: 5px;
    box-shadow:0px 0px 0px 2px White;
`;

const ToggleTextBox = styled(motion.div)`
    width: 100%;
    min-width: fit-content;
    height: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    position: absolute;
    border-radius: 5px;
    cursor: pointer;
`;

const ToggleText = styled(motion.div)`
    width: 50%;
    height: 3vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.75em;
`;

export function Toggle(props: any) {
    const [toggleDirection, setToggleDirection] = useState("0%")

    useEffect(() => {
        if (props.initMode === "0%") {
            setToggleDirection("0%")
        } else {
            setToggleDirection("100%")
        }
    }, [props.initMode])
    const toggleOn = () => {
        setToggleDirection(toggleDirection === "0%" ? "100%" : "0%")
    }

    useEffect(() => {
        props.mode(toggleDirection)
    }, [toggleDirection])
    
    return(
        <ToggleBackground
            onTap={toggleOn}
        >
            <ToggleRectangle
                onTap={toggleOn}
                animate={{
                    x: toggleDirection
                }}
                transition={{
                    type: "spring",
                    stiffness: 700,
                    damping: 50
                }}
            />
            <ToggleTextBox> 
                <ToggleText> {props.toggleOption1} </ToggleText> 
                <ToggleText> {props.toggleOption2}  </ToggleText>
            </ToggleTextBox>
        </ToggleBackground>
    )
}
