import React, { useEffect, useState } from "react";
import "./Selectors.modules.css"

interface Chapters {
    data: Array<chapterArray>;
}

interface chapterArray {
    id: string;
    number: string;
}

export default function Chapter(props: any) {
    let book = props.book;
    const [chapters, setChapters] = useState<Chapters>();
    const fetchChapter = async () => {
        const response = await fetch (
        `https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-02/books/${book}/chapters`, {
            headers: {
                "api-key": "4763a1cc274c12d0850e11218008dd79"
            }
        }
    );
    const data = await response.json(); 
    setChapters(data)
    };

    useEffect(() => {
        fetchChapter()
    }, []);

    const [selectedChapter, setSelectedChapter] = useState(""); 
    const [selectedChapterIndex, setChapterIndex] = useState(0);
    
    if (selectedChapter !== "") {
        props.chapter(selectedChapter);
    }

    return(
        <div className="Chapter-button-row"> 
            {chapters?.data.map((chapterArray, index) => {
                if (chapterArray.number !== "intro") {
                    let chapterIndex = index;
                    if (selectedChapterIndex === index) {
                        return( 
                            <button 
                                key={index} 
                                className="Chapter-buttons-selected" 
                                onClick={() => {
                                    setSelectedChapter(chapterArray.number); 
                                    setChapterIndex(chapterIndex);
                                }}> 
                                <h2 className="Chapter-button-text" key={chapterArray.number}> {chapterArray.number} </h2>
                            </button>
                        ) 
                    } else {
                        return(
                            <button 
                                key={index} 
                                className="Chapter-buttons" 
                                onClick={() => {
                                    setSelectedChapter(chapterArray.number); 
                                    setChapterIndex(chapterIndex);
                                    }}> 
                                <h2 className="Chapter-button-text" key={chapterArray.number}> {chapterArray.number} </h2> 
                            </button>
                        )
                    }
                } else {
                    return(
                        <></>
                    )
                }
            })}
        </div>
    )
}