import React, { useEffect, useState } from "react";
import "./Selectors.modules.css"
import searchIcon from './Icons/searchicon.png'

interface Books {
    data: Array<bookArray>;
}

interface bookArray {
    name: string;
    id: string;
}

export default function Book(props: any = "") {
    const [books, setBooks] = useState<Books>();
    const fetchBook = async () => {
        const response = await fetch (
        "https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-02/books?include-chapters=false&include-chapters-and-sections=false", {
            headers: {
                "api-key": "4763a1cc274c12d0850e11218008dd79"
            }
        }
    );
    const data = await response.json(); 
    setBooks(data)
    };

    useEffect(() => {
        fetchBook()
    }, []);

    const [selectedBook, setSelectedBook] = useState(""); 
    const [selectedBookIndex, setBookIndex] = useState(0);
    const [selectedBookName, setSelectedBookName] = useState("");

    if (selectedBook !== "") {
        props.book(selectedBook);
    }

    if (selectedBookName !== "") {
        props.bookName(selectedBookName);
    }

    const [inputText, setInputText] = useState("")

    const handleChange = (e: { preventDefault: () => void; target: { value: React.SetStateAction<string>; }; }) => {
        e.preventDefault();
        setInputText(e.target.value);
      };

      function SearchBook() {
        if (inputText.length > 0) {
            return(
                <>
                <div className="Book-button-row"> 
                    {books?.data.map((bookArray, index) => {
                        let bookIndex = index; 
                        if (bookArray.name.includes(inputText) || bookArray.name.toLowerCase().includes(inputText)) {
                            if (selectedBookIndex === index) {
                                return( 
                                    <>
                                    <button 
                                        key={index} 
                                        className="Book-buttons" 
                                        onClick={() => {
                                            setSelectedBook(bookArray.id); 
                                            setBookIndex(bookIndex); 
                                            setSelectedBookName(bookArray.name);
                                        }}> 
                                        <h2 key ={bookArray.name} className="Book-name"> {bookArray.name} </h2>
                                    </button>
                                    {/* <hr key={bookArray.id} className="horizontal-line"/> */}
                                    </>
                                ) 
                            } else {
                                return(
                                    <>
                                    <button 
                                        key={index} 
                                        className="Book-buttons" 
                                        onClick={() => {
                                            setSelectedBook(bookArray.id); 
                                            setBookIndex(bookIndex); 
                                            setSelectedBookName(bookArray.name);
                                        }}> 
                                        <h2 key ={bookArray.name} className="Book-name"> {bookArray.name} </h2>
                                    </button>
                                    </>
                                )
                            }
                        }
                    })}
                </div>
                </>
            )
        } else { 
            return(
                <>
                <div className="Book-button-row"> 
                    {books?.data.map((bookArray, index) => {
                        let bookIndex = index; 
                        if (selectedBookIndex === index) {
                            return( 
                                <>
                                <button 
                                    key={index} 
                                    className="Book-buttons" 
                                    onClick={() => {
                                        setSelectedBook(bookArray.id); 
                                        setBookIndex(bookIndex); 
                                        setSelectedBookName(bookArray.name);
                                    }}> 
                                    <h2 key ={bookArray.name} className="Book-name"> {bookArray.name} </h2>
                                </button>
                                </>
                            ) 
                        } else {
                            return(
                                <>
                                <button 
                                    key={index} 
                                    className="Book-buttons" 
                                    onClick={() => {
                                        setSelectedBook(bookArray.id); 
                                        setBookIndex(bookIndex); 
                                        setSelectedBookName(bookArray.name);
                                    }}> 
                                    <h2 key ={bookArray.name} className="Book-name"> {bookArray.name} </h2>
                                </button>
                                {/* <hr key={bookArray.id} className="horizontal-line"/> */}
                                </>
                            )
                        }
                    })}
                </div>
                </>
            )
        }
    }   

    return (
        <>
        <div className="Search-bar">
        <img src={searchIcon} alt="Search Icon" className="Search-icon"/>
        <input 
            className= "Input-search-bar"
            type = "text"
            placeholder="Search"
            onChange={handleChange}
            value={inputText} />
        </div>
        <SearchBook />
        </>
      )
    
}